export const SeeMore = () => (
    <svg width="40pt" height="40pt" version="1.1" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-138.15,-60.47)">
            <g transform="matrix(.093502 0 0 .093502 125.23 54.816)">
                <path d="m352.05 488.27c-117.95 0-213.9-95.957-213.9-213.9s95.957-213.9 213.9-213.9c117.94 0 213.9 95.957 213.9 213.9s-95.949 213.9-213.9 213.9zm0-411c-108.68 0-197.1 88.418-197.1 197.1 0 108.68 88.418 197.1 197.1 197.1 108.68 0 197.1-88.418 197.1-197.1 0-108.68-88.418-197.1-197.1-197.1z" />
                <g>
                    <path d="m265.77 375.63c-7.0602-6.0185-7.9077-16.622-1.8865-23.685l141.99-166.57c6.0185-7.0602 16.622-7.9077 23.685-1.8864 7.0602 6.0185 7.9077 16.622 1.8865 23.685l-141.99 166.57c-6.0185 7.0602-16.619 7.9046-23.683 1.8834z" />
                    <path d="m416.73 299.45c-3.588-3.0586-5.8726-7.5983-5.9035-12.681l0.94046-84.443-79.924 15.142c-9.1032 1.7974-17.942-4.1154-19.745-13.213-1.7999-9.1003 4.1124-17.945 13.213-19.745l99.363-15.667c4.7444-0.65218 9.5419 0.75291 13.186 3.8597l0.10107 0.0862c3.6519 3.1644 5.7684 7.7507 5.7962 12.589l0.66751 101.16c0.0603 9.2804-7.4085 16.85-16.689 16.91-4.1928 0.0291-8.0453-1.4789-11.006-4.0031z" />
                </g>
            </g>
        </g>
    </svg>
)